<template>
  <LayoutVendor
    :sidebarActiveItem="{
      value: 'order',
      children: [
        quote && quote.status === STATUS.QUOTE_APPROVED.quote_value
          ? 'accepted'
          : order && order.status === STATUS.COMPLETED.value
          ? 'completed'
          : 'request',
      ],
    }"
  >
    <template v-slot:title>
      <span v-if="quote && quote.status === STATUS.QUOTE_APPROVED.quote_value">
        Accepted Orders
      </span>
      <span v-else-if="order && order.status === STATUS.COMPLETED.value">
        Completed Orders
      </span>
    </template>
    <div class="p-2 p-md-5 bg-gray-light-4">
      <AppLoading v-if="!quote || !order" fillSpace></AppLoading>
      <div v-else class="quote-view m-auto">
        <GoBackBtn>Back</GoBackBtn>
        <!-- Vendor Detail Row -->
        <OrderDetails :order="order" :quote="quote"></OrderDetails>

        <h4 class="mt-4 font-weight-4">TIMELINE</h4>
        <!-- Status Row -->
        <div class="w-100 quote-view-status-container">
          <div class="w-100 overflow-auto">
            <div class="quote-view-status-container-content row">
              <div class="col-12">
                <AppCard class="p-4">
                  <OrderStatusIndicator
                    :pending="order.created_at | readableDate(false)"
                    :approved="order.updated_at | readableDate(false)"
                    :quoteSent="
                      quote ? quote.created_at : '' | readableDate(false)
                    "
                    :quoteApproved="
                      quote ? quote.approved_at : '' | readableDate(false, true)
                    "
                    :completed="
                      quote
                        ? quote.completed_at
                        : '' | readableDate(false, true)
                    "
                  ></OrderStatusIndicator>
                </AppCard>
              </div>
            </div>
          </div>
        </div>

        <!-- Product Table -->
        <div class="mt-5 mb-5 bg-white">
          <!--Header  -->
          <div class="bg-primary d-none d-md-block">
            <div class="row">
              <div class="col-3"><p class="m-2 pl-2">Product</p></div>
              <div class="col-3"><p class="m-2">Variant</p></div>
              <div class="col-2"><p class="m-2">Qty</p></div>
              <div class="col-2"><p class="m-2">Price</p></div>
              <div class="col-2"><p class="m-2">Total</p></div>
            </div>
          </div>
          <!-- Body -->
          <div
            v-for="(product, index) in quote.products"
            :key="`product-item-${index}`"
            class="p-2 mb-3 shadow-40"
          >
            <!-- Web View -->
            <div class="row d-none d-md-flex">
              <div class="col-3">
                <div class="row p-1">
                  <div class="col-3">
                    <AppMediaViewer
                      aspect-ratio="1/1"
                      :src="product.product | thumbnail"
                      width="100%"
                      class="rounded-circle"
                    ></AppMediaViewer>
                  </div>
                  <div class="col-9">
                    <p>{{ product.product.name }}</p>
                  </div>
                </div>
                <AppBtn
                  text
                  class="mt-1 p-0 text-d"
                  @click="viewAttachments(product.product.files)"
                >
                  <AppIcon name="document" class="d-inline"></AppIcon>
                  <span>{{ product.product.files.length }} Attached File</span>
                </AppBtn>
              </div>
              <div class="col-3 d-flex align-items-center">
                <span>{{
                  product.variant ? product.variant.name : 'N/A'
                }}</span>
              </div>
              <div class="col-2 d-flex align-items-center">
                <div v-if="product.quantity_description">
                  <span>
                    {{
                      `${product.quantity} ${product.quantity_description.name}`
                    }}
                  </span>
                  <span class="d-inline-block text-caption"
                    >({{
                      `${
                        parseInt(product.quantity) * product.conversion
                      } pieces`
                    }})</span
                  >
                </div>
                <span v-else>{{ `${product.quantity} pcs` }}</span>
              </div>
              <div class="col-2 d-flex align-items-center">
                <span>$ {{ product.price }}</span>
              </div>
              <div class="col-2 d-flex align-items-center">
                <span class="font-weight-bold"
                  >$ {{ product.total_price }}</span
                >
              </div>
            </div>

            <!-- Mobile View -->
            <div class="d-md-none">
              <div class="mt-2">
                <div class="d-flex">
                  <!-- Details -->
                  <div class="mr-2">
                    <AppMediaViewer
                      width="42px"
                      :src="product.product | thumbnail"
                    ></AppMediaViewer>
                  </div>
                  <div class="row">
                    <div class="col-12 d-flex align-items-center">
                      <span class="text-body-2 font-weight-5 text-truncate">
                        {{ product.product.name }}
                      </span>
                    </div>
                    <div class="col-12 d-flex align-items-center">
                      <p class="m-0">
                        {{ product.variant ? product.variant.name : 'N/A' }}
                      </p>
                    </div>
                    <div class="col-6 d-flex align-items-center">
                      <p class="m-0">$ {{ product.price }}</p>
                    </div>
                    <div
                      class="
                        col-6
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <p class="text-truncate m-0">
                        x {{ `${product.quantity} pcs` }}
                      </p>
                    </div>
                    <div
                      class="
                        mt-1
                        col-12
                        d-flex
                        align-items-center
                        justify-content-end
                      "
                    >
                      <p class="text-truncate m-0">
                        <span>SUBTOTAL: </span>
                        <span class="font-weight-6">
                          $
                          {{ product.price * product.quantity }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- Attach File -->
                <div
                  v-if="
                    product.product.files && product.product.files.length > 0
                  "
                  class="mt-auto mb-0 d-flex flex-column"
                >
                  <AppDivider class="mt-2 mt-2"></AppDivider>
                  <AppBtn
                    text
                    prependIcon="document_attachment"
                    color="dark"
                    class="pl-0 mr-auto text-body-3"
                    @click="viewAttachments(product.product.files)"
                  >
                    {{ product.product.files.length }} Attached File
                  </AppBtn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Order Total Expense -->
        <div class="quote-view-total ml-auto">
          <h4>TOTAL</h4>
          <div class="p-3 bg-white shadow-60">
            <div class="row mb-3">
              <div
                class="
                  col-8
                  text-body-3
                  font-weight-4
                  text-caption text-md-body
                "
              >
                MATERIALS TOTAL AMOUNT
              </div>
              <div class="col-4 font-weight-4 text-right">
                $ {{ total.productTotal }}
              </div>
            </div>

            <div class="row mb-3">
              <div
                class="
                  col-8
                  text-body-3
                  font-weight-4
                  text-caption text-md-body
                "
              >
                <span>TOTAL DELIVERY FEE</span>
                <AppIcon
                  hoverable
                  class="d-inline"
                  :name="showDeliveryDetails ? 'caret_up' : 'caret_down'"
                  @click="showDeliveryDetails = !showDeliveryDetails"
                ></AppIcon>
              </div>
              <div class="col-4 font-weight-4 text-right">
                $ {{ total.totalDelivery }}
              </div>
            </div>

            <div v-if="showDeliveryDetails" class="pl-1 text-gray-dark-5">
              <div class="row mb-3">
                <div
                  class="
                    col-8
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-caption-2 text-md-body-2
                  "
                >
                  SHIP TO ADDRESS
                </div>
                <div
                  class="
                    col-4
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-right
                  "
                >
                  $ {{ quote.shipping_address_fee }}
                </div>
              </div>
              <div class="row mb-3">
                <div
                  class="
                    col-8
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-caption-2 text-caption
                  "
                >
                  TRUCK FEE
                </div>
                <div
                  class="
                    col-4
                    text-caption-2 text-md-body-2
                    font-weight-4
                    text-right
                  "
                >
                  $ {{ quote.truck_fee }}
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div
                class="
                  col-8
                  text-body-3
                  font-weight-4
                  text-caption text-md-body
                "
              >
                TAX
              </div>
              <div class="col-4 font-weight-4 text-right">
                $ {{ total.tax }}
              </div>
            </div>

            <AppDivider class="mb-3"></AppDivider>

            <div class="row mb-3">
              <div class="col-8 text-caption text-md-body">TOTAL</div>
              <div class="col-4">
                <h4 class="font-style-primary text-right">
                  $ {{ total.total }}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <!-- Action Btns -->
        <div
          v-if="quote.status === STATUS.QUOTE_APPROVED.quote_value"
          class="mt-4 d-flex flex-column flex-md-row justify-content-md-end"
        >
          <AppBtn
            v-if="!quote.delivered_at"
            :loading="loading"
            :disabled="quote.delivered_at"
            @click="confirmDelivery = true"
          >
            ORDER SHIPPED
          </AppBtn>
          <div v-else class="p-2 bg-primary-light-6 text-primary-light-1">
            ORDER SHIPPED. WAITING FOR CUSTOMER APPROVAL
          </div>
        </div>
      </div>
      <!-- Attached files viewer -->
      <AttachedFileDrawer
        v-model="attachmentDrawer"
        :files="attachmentFiles"
      ></AttachedFileDrawer>

      <!-- Confirm Delivery Modal -->
      <ActionModal
        v-if="quote && quote.status === STATUS.QUOTE_APPROVED.quote_value"
        v-model="confirmDelivery"
        @confirm="deliverOrder"
      >
        <template v-slot:title> Confirm Delivery </template>
        <template v-slot:description> Click confirm to proceed </template>
      </ActionModal>
      <!-- Success Delivery Modal -->
      <SuccessModal v-model="successDelivery" @confirm="$router.go()">
        <template v-slot:title> PRODUCT DELIVERY SUBMITTED! </template>
        <template v-slot:description>
          The buyer have to confirm that the product has been received before
          the transaction can be marked as complete
        </template>
        <template v-slot:action-btn-text> Close </template>
      </SuccessModal>
    </div>
  </LayoutVendor>
</template>

<script>
import GoBackBtn from '@/components/Partial/GoBackBtn.vue';
import AppMediaViewer from '@/shared/elements/AppMediaViewer.vue';
import AppIcon from '@/shared/elements/AppIcon.vue';
import AppBtn from '@/shared/elements/AppBtn.vue';
import AppDivider from '@/shared/elements/AppDivider.vue';
import AttachedFileDrawer from '@/components/Partial/Orders/AttachedFileDrawer.vue';
import ActionModal from '@/components/Partial/Modals/ActionModal.vue';
import Orders from '@/shared/api/Orders';
import AppLoading from '@/shared/elements/AppLoading.vue';
import _rfqFormat from '@/shared/mixins/_rfqFormat';
import _readableDateTime from '@/shared/mixins/_readableDateTime';
import _appApiHelper from '@/shared/mixins/_appApiHelper';
import OrderConstants from '@/shared/constants/OrderConstants';
import OrderDetails from '@/components/Vendor/OrderView/OrderDetails.vue';
import AppCard from '@/shared/elements/AppCard.vue';
import OrderStatusIndicator from '@/components/Vendor/OrderView/OrderStatusIndicator.vue';
import SuccessModal from '@/components/Partial/Modals/SuccessModal.vue';
import LayoutVendor from '../../../../components/Partial/Layouts/LayoutVendor/LayoutVendor.vue';
export default {
  name: 'OrderView',

  components: {
    GoBackBtn,
    AppMediaViewer,
    AppIcon,
    AppBtn,
    AppDivider,
    AttachedFileDrawer,
    ActionModal,
    AppLoading,
    OrderDetails,
    AppCard,
    OrderStatusIndicator,
    SuccessModal,
    LayoutVendor,
  },

  mixins: [_rfqFormat, _readableDateTime, _appApiHelper],

  data() {
    return {
      STATUS: OrderConstants.STATUS,
      quote: null,
      order: null,
      showDeliveryDetails: false,
      confirmDelivery: false,
      successDelivery: false,
      attachmentDrawer: false,
      attachmentFiles: [],
      id: this.$route.params?.id,
    };
  },

  computed: {
    total() {
      let totalPrices = 0;

      if (this.quote?.products?.length > 0) {
        totalPrices = this.quote.products.reduce((total, product) => {
          return total + product.total_price;
        }, 0);
      }
      const tax = 0;

      return {
        productTotal: totalPrices,
        totalDelivery: this.quote?.shipping_address_fee + this.quote?.truck_fee,
        tax,
        total:
          totalPrices +
          this.quote?.shipping_address_fee +
          this.quote?.truck_fee +
          tax,
      };
    },
  },

  filters: {
    fullName(user) {
      if (user?.information?.first_name) {
        return `${user.information.first_name} ${user.information.last_name}`;
      } else {
        return '';
      }
    },
    companyName(user) {
      if (user?.information) {
        return user.information?.company_name || user.information?.company;
      }

      return 'UNKNOWN SUPPLIER';
    },
    thumbnail(value) {
      if (value && value?.files?.length > 0) {
        const files = value.files;
        let thumb = value.files.find((file) => file.type === 'primary_image');
        return thumb?.filename || files[0]?.filename;
      } else {
        return null;
      }
    },
  },

  methods: {
    viewAttachments(attachments) {
      this.attachmentFiles = attachments;
      this.attachmentDrawer = true;
    },
    async fetchQuote() {
      const apiResponse = await Orders.getQuote(this.id);
      this.quote = apiResponse.data;
      const orderApiResponse = await Orders.getOrder(apiResponse.data.order.id);
      this.order = orderApiResponse.data;
    },
    showFileAttachments() {
      this.fileAttachmentDrawer = true;
    },
    async deliverOrder() {
      this.$store.dispatch('api/preventSuccessModal');
      const response = await Orders.editQuote(this.id, { delivered: 1 });
      this.successDelivery = response.status === 200;
    },
  },

  mounted() {
    this.fetchQuote();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
@import '@/scss/theme/_colors';

.quote-view {
  overflow-x: hidden;
  overflow-y: auto;
  &-total {
    width: 500px;
    @include media-md('below') {
      width: 100%;
    }
  }
  &-back-btn {
    cursor: pointer;
  }
  &-table {
    @include media-md('above') {
      min-width: 1200px;
    }
    &-container {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  &-status-container {
    /* width */
    ::-webkit-scrollbar {
      height: 0.5rem !important;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--primary-light-6) !important;
      // box-shadow: inset 1px 1px 12px -7px rgba(0, 0, 0, 0.371);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      border-radius: 60px !important;
      background-color: var(--primary) !important;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--primary-dark-3) !important;
    }

    &-content {
      width: 100%;

      @include media-md('below') {
        min-width: 1200px;
      }
    }
  }
}
</style>
